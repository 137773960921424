.contact-info {
    padding: 60px 20px;
    background: #f8f9fa;
    text-align: center;
  }
  
  .contact-info-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .contact-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  }
  
  .contact-icon {
    font-size: 30px;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .contact-card h3 {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  .contact-card p {
    font-size: 15px;
    color: #555;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .contact-info-container {
      grid-template-columns: 1fr;
    }
  }
  