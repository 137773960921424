/* Footer Styling */
.footer {
  background-color: #333;/* Black background for a bold look */
  color: #fff; /* White text for contrast */
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.footer-logo {
  flex: 1;
  text-align: center;
}

.footer-logo-img {
  max-width: 130px; /* Slightly larger logo for better visibility */
  margin-bottom: 10px;
}

.footer-tagline {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 10px;
  color: white; /* Light gray for tagline to reduce visual clutter */
}

.footer-links {
  flex: 1;
  text-align: center;
}

.footer-links h4 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #fff; /* White text for section titles */
}

.footer-links ul {
  list-style-type: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ccc; /* Lighter gray for links */
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #fff; /* White color on hover */
}

.footer-contact {
  flex: 1;
  text-align: center;
}

.footer-contact h4 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #fff; /* White color for section titles */
}

.footer-contact address {
  margin: 0;
  font-style: normal;
  color: white; /* Light gray for contact information */
}

.footer-contact a {
  color: white; /* Lighter gray for links */
  text-decoration: none;
  transition: color 0.3s ease;
}
.footer-contact p{
  color: white;
}

.footer-contact a:hover {
  color: #fff; /* White color on hover */
}

.footer-social {
  margin-top: 15px;
}

.footer-social a {
  color: #ccc; /* Lighter gray for social icons */
  margin-right: 15px;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #fff; /* White color on hover */
}

.footer-bottom {
  text-align: center;
  font-size: 1rem; /* Slightly larger text for bottom */
  margin-top: 20px;
  border-top: 1px solid #444; /* Darker separator line */
  padding-top: 10px;
  color:white; /* Lighter text color for footer bottom */
}

.footer-bottom p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-logo,
  .footer-links,
  .footer-contact {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .footer-logo-img {
    max-width: 100px;
  }

  .footer-social a {
    font-size: 1.2rem; /* Larger social icon size for smaller screens */
    margin-right: 10px;
  }
}
