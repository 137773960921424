/* Overall Header Styling */
.header-section_rr7 {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #121212; /* Dark background */
  }
  
  /* Header Content Styling */
  .header-content_rr7 {
    text-align: center;
    color: white;
    padding: 0 30px;
    z-index: 2;
  }
  
  /* Title Styling */
  .header-title_rr7 {
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: #fff;
  }
  
  /* Subtitle Styling */
  .header-subtitle_rr7 {
    font-size: 1.2rem;
    line-height: 1.8;
    max-width: 700px;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    color: #d1d1d1;
    margin-bottom: 40px;
  }
  
  /* Service Icons Section */
  .service-icons_rr7 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 30px;
  }
  
  .service-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .service-icon-img {
    color: #00bcd4; /* Light blue color for the icons */
  }
  
  .service-icon-text {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
    color: #fff;
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .header-title_rr7 {
      font-size: 2.5rem;
    }
  
    .header-subtitle_rr7 {
      font-size: 1rem;
    }
  
    .service-icons_rr7 {
      gap: 30px;
    }
  }
  
  @media (max-width: 768px) {
    .header-title_rr7 {
      font-size: 2rem;
    }
  
    .header-subtitle_rr7 {
      font-size: 0.9rem;
    }
  
    .service-icons_rr7 {
      flex-direction: column;
      gap: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .header-title_rr7 {
      font-size: 1.8rem;
    }
  
    .header-subtitle_rr7 {
      font-size: 0.8rem;
    }
  }
  