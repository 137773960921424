/* Our Mission Section Styles */
.our-mission {
    background-color: #f9f9f9;
    padding: 60px 20px;
    text-align: center;
  }
  
  .mission-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .mission-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
  }
  
  .mission-subheading {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .mission-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
  }
  
  .mission-item {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .mission-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .mission-icon {
    font-size: 3rem;
    color: #0066cc;
    margin-bottom: 15px;
  }
  
  .mission-heading {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .mission-text {
    font-size: 1.1rem;
    color: #777;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .mission-title {
      font-size: 2rem;
    }
  
    .mission-subheading {
      font-size: 1rem;
    }
  
    .mission-items {
      grid-template-columns: 1fr;
    }
  
    .mission-item {
      padding: 20px;
    }
  
    .mission-icon {
      font-size: 2.5rem;
    }
  
    .mission-heading {
      font-size: 1.6rem;
    }
  
    .mission-text {
      font-size: 1rem;
    }
  }
  