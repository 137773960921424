/* Overall section style */
.technology-stack_uu7 {
    padding: 5rem 0;
    background-color: #f8f9fa;
    color: #333;
    text-align: center;
  }
  
  .technology-stack_uu7 h2 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    color: #343a40;
  }
  
  .technology-stack_uu7 p {
    font-size: 1.125rem;
    color: #6c757d;
    margin-bottom: 3rem;
    line-height: 1.6;
  }
  
  .container_uu7 {
    width: 85%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Grid for technology cards */
  .tech-grid_uu7 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
  
  .tech-card_uu7 {
    background-color: #fff;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .tech-card_uu7:hover {
    transform: translateY(-12px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
  
  /* Icon container design */
 /* Icon container design */
.icon-container_uu7 {
    background-color: #28a745; /* Fresh green color */
    padding: 2rem; /* Balanced padding */
    color: #fff;
    font-size: 3.5rem; /* Icon size */
    margin-bottom: 1.5rem;
    border-radius: 12px; /* Rounded corners instead of a circle */
    display: inline-block;
    width: 90px; /* Set width for consistency */
    height: 90px; /* Set height for consistency */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  }
  
  .icon-container_uu7::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.2); /* Shine effect */
    transform: scale(0);
    transition: transform 0.3s ease;
  }
  
  .icon-container_uu7:hover {
    background-color: #218838; /* Darker green on hover */
    transform: translateY(-5px); /* Slight upward movement on hover */
  }
  
  .icon-container_uu7:hover::before {
    transform: scale(1); /* Shine effect on hover */
  }
  
  .icon-container_uu7 svg {
    z-index: 1; /* Ensure icon is above the shine effect */
    display: block;
    margin: 0 auto; /* Center the icon */
  }
  
  /* Text inside tech card */
  h3 {
    font-size: 1.7rem;
    font-weight: 700;
    color: #343a40;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.1rem;
    color: #6c757d;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .tech-grid_uu7 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .tech-grid_uu7 {
      grid-template-columns: 1fr;
    }
  }
  