/* Tech Stack Section */
.tech-stack {
    text-align: center;
    padding: 50px 20px;
    font-family: 'Arial', sans-serif;
    background: #f8f9fa;
  }
  
  .tech-stack h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Tech Grid */
  .tech-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 900px;
    margin: auto;
  }
  
  /* Tech Card */
  .tech-card {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    text-align: center;
    border: 2px solid transparent;
  }
  
  .tech-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Tech Icon */
  .tech-icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  /* Colors for Icons */
  .react { color: #61DBFB; }
  .node { color: #68A063; }
  .php { color: #787CB5; }
  .mysql { color: #00758F; }
  .mongodb { color: #4DB33D; }
  .aws { color: #FF9900; }
  .hostinger { color: #673AB7; }
  .firebase { color: #FFCA28; }
  .javascript { color: #F7DF1E; }
  .java { color: #007396; }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .tech-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  
    .tech-icon {
      font-size: 35px;
    }
  }
  