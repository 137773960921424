/* About Section Styles */
.about-section {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  .about-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .about-header p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .about-content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .about-card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .about-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .about-card p {
    color: #666;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .values-card {
    background: linear-gradient(135deg, #4caf50, #2e7d32);
    color: #fff;
  }
  
  .values-card h3 {
    color: #fff;
  }
  
  .values-card ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .values-card li {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .values-card strong {
    font-weight: bold;
  }
  
  @media (min-width: 768px) {
    .about-content {
      grid-template-columns: 1fr 1fr;
    }
  
    .about-header h2 {
      font-size: 3rem;
    }
  
    .about-header p {
      font-size: 1.3rem;
    }
  }
  