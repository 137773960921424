/* Packages Section */
.packages-section {
    padding: 40px 20px;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Packages Container */
  .packages-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  /* Package Card */
  .package-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .package-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Package Title */
  .package-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    margin-bottom: 10px;
  }
  
  /* Package Price */
  .package-price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  /* Features List */
  .features-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    color: #666;
  }
  
  .features-list li {
    font-size: 1rem;
    margin: 5px 0;
  }
  
  /* View Template Button */
  .view-template-btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .view-template-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .packages-container {
      flex-direction: column;
      align-items: center;
    }
  
    .package-card {
      width: 100%;
      max-width: 350px;
    }
  }
  