/* Services Overview Styles */
.services-overview {
    background-color: #f9f9f9;
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-header h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .section-header p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2.5rem;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .service-card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  .icon-container {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .service-icon {
    font-size: 4rem;
  }
  
  .service-card h3 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  /* Responsive Styles */
  @media (min-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .section-header h2 {
      font-size: 3rem;
    }
  
    .section-header p {
      font-size: 1.3rem;
    }
  }
  