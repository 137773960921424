/* Main Header Section */
.contact-header {
  position: relative;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  color: white;
  padding: 100px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Content Container */
.header-container {
  max-width: 900px;
  position: relative;
  z-index: 2;
}

/* Floating Icons */
.floating-icons {
  display: flex;
  justify-content: center;
  gap: 35px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  width: 120px;
  height: 120px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.icon-box:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.25);
}

.icon {
  font-size: 2.5rem;
  color: #f1c40f; /* Yellow-Golden for premium look */
}

.icon-box span {
  font-size: 1rem;
  font-weight: 500;
  color: #ecf0f1; /* Light Grey */
  margin-top: 8px;
}

/* Header Text */
.header-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #f8f9fa;
}

.header-subtitle {
  font-size: 1.3rem;
  font-weight: 300;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  color: #bdc3c7;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header-title {
    font-size: 2.5rem;
  }

  .header-subtitle {
    font-size: 1.1rem;
  }

  .floating-icons {
    gap: 20px;
  }

  .icon-box {
    padding: 10px;
    width: 100px;
    height: 100px;
  }

  .icon {
    font-size: 2rem;
  }
}
