.social-links {
    text-align: center;
    padding: 50px 20px;
    background: linear-gradient(135deg, #f8f9fa, #e9ecef);
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
  }
  
  .social-links h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .social-links p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .social-icon:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Colors */
  .linkedin:hover {
    background: #0077b5;
    color: #fff;
  }
  .instagram:hover {
    background: #e4405f;
    color: #fff;
  }
  .facebook:hover {
    background: #1877f2;
    color: #fff;
  }
  .twitter:hover {
    background: #1da1f2;
    color: #fff;
  }
  .website:hover {
    background: #28a745;
    color: #fff;
  }
  
  .social-icon svg {
    width: 24px;
    height: 24px;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover svg {
    color: #fff;
  }
  
  @media (max-width: 768px) {
    .social-icons {
      gap: 10px;
    }
    .social-icon {
      width: 45px;
      height: 45px;
    }
    .social-links h2 {
      font-size: 20px;
    }
    .social-links p {
      font-size: 14px;
    }
  }
  