/* Reset some default styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .header-section_ee45 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .header-content_ee45 {
    margin-bottom: 20px;
  }
  
  .header-title_ee45 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 10px;
  }
  
  .header-subtitle_ee45 {
    font-size: 1.3rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .header-description_ee45 {
    font-size: 1rem;
    color: #777;
    margin-bottom: 30px;
    max-width: 800px;
  }
  
  .cta-button_ee45 {
    background-color: #007bff;
    color: #fff;
    font-size: 1rem;
    padding: 12px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button_ee45:hover {
    background-color: #0056b3;
  }
  
  .header-image_ee45 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .header-illustration_ee45 {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header-section_ee45 {
      padding: 20px;
    }
  
    .header-title_ee45 {
      font-size: 2rem;
    }
  
    .header-subtitle_ee45 {
      font-size: 1.1rem;
    }
  
    .header-description_ee45 {
      font-size: 0.9rem;
    }
  
    .cta-button_ee45 {
      padding: 10px 25px;
      font-size: 0.9rem;
    }
  
    .header-image_ee45 {
      margin-top: 20px;
    }
  }
  