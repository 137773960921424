/* Why Choose Us Section Styles */
.why-choose-us_gg5 {
    background-color: #f9f9f9;
    padding: 60px 20px;
    text-align: center;
  }
  
  .why-content_gg5 {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .why-title_gg5 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
  }
  
  .why-description_gg5 {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .why-grid_gg5 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 40px;
  }
  
  .why-item_gg5 {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .why-item_gg5:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  .check-icon_gg5 {
    font-size: 2rem;
    color: #00b894;
    margin-bottom: 15px;
  }
  
  .item-heading_gg5 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .item-text_gg5 {
    font-size: 1.1rem;
    color: #777;
    line-height: 1.5;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .why-title_gg5 {
      font-size: 2rem;
    }
  
    .why-description_gg5 {
      font-size: 1rem;
    }
  
    .why-grid_gg5 {
      grid-template-columns: 1fr;
    }
  
    .why-item_gg5 {
      padding: 20px;
    }
  
    .check-icon_gg5 {
      font-size: 1.5rem;
    }
  
    .item-heading_gg5 {
      font-size: 1.6rem;
    }
  
    .item-text_gg5 {
      font-size: 1rem;
    }
  }
  