/* Industries Section Styles */
.industries-section {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .industries-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .industries-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2.5rem;
  }
  
  .industries-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .industry-card {
    background: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .industry-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .icon-container {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .industry-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .industry-description {
    font-size: 1rem;
    color: #666;
  }
  
  .industries-cta {
    margin-top: 3rem;
  }
  
  .cta-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .industries-title {
      font-size: 2rem;
    }
  
    .industries-subtitle {
      font-size: 1rem;
    }
  }
  