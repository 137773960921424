/* Android Packages Section */
.android-packages {
    text-align: center;
    padding: 50px 20px;
    background: #f8f9fa;
    font-family: 'Arial', sans-serif;
  }
  
  .android-packages h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 30px;
  }
  
  /* Package Grid */
  .package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Package Card */
  .package-card {
    background: white;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-top: 5px solid transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .package-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  /* Package Title */
  .package-card h3 {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  
  /* Colors for Different Packages */
  .basic { border-color: #3498db; }
  .silver { border-color: #95a5a6; }
  .gold { border-color: #f39c12; }
  .diamond { border-color: #9b59b6; }
  
  /* Pricing */
  .pricing {
    margin: 15px 0;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .price-inr, .price-usd {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
  
  .price-inr span {
    color: #27ae60;
    font-weight: bold;
  }
  
  .price-usd span {
    color: #2980b9;
    font-weight: bold;
  }
  
  /* Suitable For */
  .suitable-for {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
  }
  
  /* Features List */
  .features {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    text-align: left;
  }
  
  .features li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-weight: 500;
    color: #333;
  }
  
  .features li svg {
    color: #27ae60;
    margin-right: 8px;
    font-size: 16px;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .package-grid {
      grid-template-columns: 1fr;
    }
  }
  