.template-grid_js6 {
    text-align: center;
    padding: 4rem 2rem;
    background: #f8f9fa;
}

.template-grid_js6 h2 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
    color: #222;
}

.template-grid_js6 p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 2rem;
}

.grid_js6 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 20px;
    justify-content: center;
}

/* Card Styling */
.card_js6 {
    background: #fff;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.card_js6:hover {
    transform: translateY(-5px);
}

/* Large Responsive iframe */
.iframe-container {
    width: 100%;
    height: 450px;  /* Increased size for better visibility */
    border-radius: 8px;
    overflow: hidden;
}

.iframe-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

/* Title & Description */
.card_js6 h3 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 15px;
}

.card_js6 p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
}

/* Button Group */
.button-group {
    display: flex;
    gap: 12px;
    justify-content: center;
    margin-top: 15px;
}

/* View Template Button */
.view-btn_js6,
.fullscreen-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #007bff;
    color: #fff;
    text-decoration: none;
    padding: 12px 18px;
    border-radius: 5px;
    transition: background 0.3s ease-in-out;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.fullscreen-btn {
    background: #28a745;
}

.view-btn_js6 .icon,
.fullscreen-btn .icon {
    margin-right: 8px;
}

.view-btn_js6:hover {
    background: #0056b3;
}

.fullscreen-btn:hover {
    background: #218838;
}

/* Responsive Design */
@media (max-width: 768px) {
    .iframe-container {
        height: 300px;
    }

    .grid_js6 {
        grid-template-columns: 1fr;
    }
}
