.map-container {
    text-align: center;
    margin-top: 30px;
  }
  
  .map-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  