.contact-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .contact-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #222;
  }
  
  .contact-subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 15px;
    transition: 0.3s ease-in-out;
  }
  
  .input-group:hover {
    background: #eef2ff;
  }
  
  .input-icon {
    color: #3498db;
    margin-right: 10px;
  }
  
  input,
  select,
  textarea {
    flex: 1;
    border: none;
    outline: none;
    background: transparent;
    font-size: 1rem;
    padding: 5px;
  }
  
  select {
    cursor: pointer;
  }
  
  textarea {
    resize: none;
  }
  
  .submit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3498db;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .submit-button:hover {
    background: #2874a6;
  }
  
  .send-icon {
    margin-left: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .contact-form-container {
      width: 90%;
    }
  }
  