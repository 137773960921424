/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
  }
  
  /* Pricing Container */
  .pricing-container {
    text-align: center;
    padding: 50px 20px;
  }
  
  .pricing-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .pricing-subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
  }
  
  /* Pricing Grid */
  .pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: auto;
  }
  
  /* Pricing Card */
  .pricing-card {
    background: white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Gold Package */
  .gold-package {
    background: linear-gradient(135deg, #FFD700, #B8860B);
    color: white;
  }
  
  .gold-package .pricing-name {
    color: white;
  }
  
  .gold-package .pricing-price,
  .gold-package .pricing-price-usd {
    color: white;
  }
  
  .gold-package .pricing-button {
    background: white;
    color: #B8860B;
  }
  
  .gold-package .pricing-button:hover {
    background: #fff5cc;
  }
  
  /* Pricing Details */
  .pricing-name {
    font-size: 1.8rem;
    font-weight: bold;
    color: #222;
  }
  
  .pricing-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
  }
  
  .pricing-price-usd {
    font-size: 1.2rem;
    color: #666;
  }
  
  .pricing-features {
    list-style: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .pricing-features li {
    font-size: 1rem;
    padding: 5px 0;
  }
  
  /* Choose Plan Button */
  .pricing-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  
  .pricing-button:hover {
    background: #0056b3;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .pricing-title {
      font-size: 2rem;
    }
  
    .pricing-subtitle {
      font-size: 1rem;
    }
  
    .pricing-grid {
      grid-template-columns: 1fr;
    }
  }
  