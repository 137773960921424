.android-stack {
    text-align: center;
    padding: 50px 20px;
    background: #f8f9fa;
}

.android-stack h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 30px;
}

.stack-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-content: center;
}

.stack-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease-in-out;
}

.stack-card:hover {
    transform: translateY(-5px);
}

.icon {
    font-size: 3rem;
    color: #007bff;
    margin-bottom: 10px;
}

.stack-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #333;
}

.stack-card p {
    font-size: 0.9rem;
    color: #666;
}
