/* Hero Section Styles */
.hero_ff5 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 0 20px;
    position: relative;
  }
  
  .hero_ff5::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    z-index: 1;
  }
  
  .hero-content_ff5 {
    z-index: 2;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero-title_ff5 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    letter-spacing: 2px;
  }
  
  .hero-tagline_ff5 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-style: italic;
    color: white;
  }
  
  .hero-subheading_ff5 {
    font-size: 1.2rem;
    margin-bottom: 40px;
    line-height: 1.5;
    color: white;
  }
  
  .cta-btn_ff5 {
    background-color: #0066cc;
    color: #fff;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
  }
  
  .cta-btn_ff5:hover {
    background-color: #004d99;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .hero-title_ff5 {
      font-size: 2.5rem;
    }
  
    .hero-tagline_ff5 {
      font-size: 1.3rem;
    }
  
    .hero-subheading_ff5 {
      font-size: 1rem;
    }
  
    .cta-btn_ff5 {
      padding: 12px 25px;
    }
  }
  