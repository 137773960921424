/* Base Navbar Styles */
.navbar {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Logo */
.navbar-logo img.logo {
  width: 150px;
  height: auto;
}

/* Nav Links */
.navbar-nav {
  display: flex;
  gap: 1rem;
}

.navbar-nav.active {
  display: block;
  position: absolute;
  top: 60px;
  right: 0;
  background: #444;
  width: 100%;
  padding: 1rem 0;
  text-align: center;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.nav-list li {
  position: relative;
}

.nav-list a {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  display: block;
}

.nav-list a:hover {
  background-color: #555;
  border-radius: 4px;
}

/* Dropdown */
.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #444;
  padding: 0.5rem;
  list-style: none;
}

.dropdown-menu li {
  margin: 0.5rem 0;
}

.dropdown-menu a {
  color: white;
}

/* Search Bar */
.navbar-search {
  margin-left: auto;
}

.navbar-search input {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  outline: none;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Show Hamburger in Mobile View */
  .hamburger {
    display: block;
  }

  /* Hide Navbar Links in Mobile View */
  .navbar-nav {
    display: none;
    flex-direction: column;
  }

  .navbar-nav.active {
    display: block;
  }

  .nav-list {
    flex-direction: column;
  }

  /* Hide Search Bar in Mobile View */
  .navbar-search {
    display: none;
  }
}
