/* Hero Section Styles */
.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Add a dark overlay on the background */
.hero-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust opacity to control darkness */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
  text-align: center;
  padding: 2rem;
  max-width: 800px;
  animation: none;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.hero-content.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.hero-content h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Add text shadow for better visibility */
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #e0e0e0;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6); /* Add text shadow */
}

.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.cta-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.cta-button.primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.cta-button.primary:hover {
  background-color: #0056b3;
}

.cta-button.secondary {
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
}

.cta-button.secondary:hover {
  background-color: #fff;
  color: #007bff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .cta-button {
    width: 100%;
    text-align: center;
  }
}
