/* Floating Buttons Container */
.floating-buttons {
    position: fixed;
    bottom: 30px;
    left: 30px;
    right: 30px;
    display: flex;
    justify-content: space-between;
    z-index: 1000;
  }
  
  /* General Floating Button Styling */
  .floating-button {
    width: 60px;
    height: 60px;
    background-color: #25D366; /* WhatsApp Green */
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 30px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    text-decoration: none;
  }
  
  .floating-button.call {
    background-color: #34b7f1; /* Call Blue */
  }
  
  .floating-button.whatsapp {
    background-color: #25D366; /* WhatsApp Green */
  }
  
  .floating-button:hover {
    background-color: #128C7E; /* Darker Green for WhatsApp */
  }
  
  .floating-button.call:hover {
    background-color: #007BFF; /* Darker Blue for Call */
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .floating-buttons {
      bottom: 20px;
      left: 20px;
      right: 20px;
    }
  
    .floating-button {
      width: 50px;
      height: 50px;
      font-size: 26px;
    }
  }
  