/* Call to Action Section Styles */
.cta-section {
    background-color: #2d3436;
    color: #fff;
    padding: 80px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
  }
  
  .cta-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cta-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #fff;
  }
  
  .cta-description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #dfe6e9;
  }
  
  .cta-button {
    background-color: #00b894;
    color: #fff;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    border-radius: 50px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
  }
  
  .cta-button:hover {
    background-color: #00cec9;
    transform: scale(1.05);
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .cta-title {
      font-size: 2rem;
    }
  
    .cta-description {
      font-size: 1rem;
    }
  
    .cta-button {
      font-size: 1rem;
      padding: 12px 25px;
    }
  }
  